<template>
  <div id="contents" class="report_output">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button
            class="btn_admin"
            @click="openModal"
            :disabled="!filterCheckPrint"
          >
            성적서 출력
          </button>
          <quality-print-modal
            v-show="isQualityModalOpen"
            :imageList="imageList"
            @onclose="closeModal"
          ></quality-print-modal>
          <div
            class="modal_layer"
            v-if="isQualityModalOpen"
            @click="closeModal"
          ></div>
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active"><a>검사성적서 출력</a></li>
        </ul>
      </div>
      <quality-print-form></quality-print-form>
    </div>
  </div>
</template>

<script>
import QualityPrintForm from '@/views/forms/Quality/Print/QualityPrintForm';
import QualityPrintModal from '@/layouts/components/QualityPrintModal';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import { mapGetters } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    QualityPrintForm,
    QualityPrintModal,
  },
  data() {
    return {
      isQualityModalOpen: false,
      imageList: [],
    };
  },
  computed: {
    ...mapGetters({
      material_defect_list: 'getMaterialDefectListFromQualityPrint',
      product_defect_list: 'getProductDefectListFromQualityPrint',
      seq_defect_list: 'getSeqDefectListFromQualityPrint',
      radioState: 'getRadioStateFromQualityPrint',
    }),
    filterCheckPrint() {
      let found = null;
      if (this.radioState.radio_material) {
        found = this.material_defect_list.find(x => x.check_print);
      } else if (this.radioState.radio_product) {
        found = this.product_defect_list.find(x => x.check_print);
      } else if (this.radioState.radio_process) {
        found = this.seq_defect_list.find(x => x.check_print);
      }
      return found == undefined ? false : true;
    },
  },
  methods: {
    openModal() {
      let qualityModal = document.querySelector('.quality_print_modal tbody');
      let qualityT = document.querySelectorAll('.report_output .print_active');
      qualityT.forEach(el => {
        qualityModal.appendChild(el.cloneNode(true));
      });

      if (
        this.material_defect_list !== undefined &&
        this.radioState.radio_material
      ) {
        this.material_defect_list.forEach((el, index) => {
          el.image_index = index + 1;
        });
        this.imageList = this.material_defect_list.filter(
          x => x.image != null && x.check_print !== false,
        );
      }
      if (this.seq_defect_list !== undefined && this.radioState.radio_process) {
        this.seq_defect_list.forEach((el, index) => {
          el.image_index = index + 1;
        });
        this.imageList = this.seq_defect_list.filter(
          x => x.image != null && x.check_print !== false,
        );
      }
      if (
        this.product_defect_list !== undefined &&
        this.radioState.radio_product
      ) {
        this.product_defect_list.forEach((el, index) => {
          el.image_index = index + 1;
        });
        this.imageList = this.product_defect_list.filter(
          x => x.image != null && x.check_print !== false,
        );
      }

      this.isQualityModalOpen = true;
    },
    closeModal() {
      let qualityModal = document.querySelector('.quality_print_modal tbody');
      let qualityT = qualityModal.querySelectorAll('tr');
      if (qualityT !== undefined) {
        qualityT.forEach(el => {
          qualityModal.removeChild(el);
        });
        this.imageList = [];
      }
      this.isQualityModalOpen = false;
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      await this.$store.commit('InitQualityPrintPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
