<template>
  <div class="article">
    <div class="contents">
      <div class="tbl_option">
        <h5>출력항목 선택</h5>
        <div class="option_cont">
          <div class="option_date">
            <span>검사일자 조회</span>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="startDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="endDate"
                @change="setEndDate($event)"
              />
            </div>
          </div>
          <div class="input_radio">
            <div>
              <label
                for="radio1"
                class="label"
                :class="{ active: radioState.radio_material }"
                @click="
                  () => {
                    $store.commit('setRadioStateToQualityPrint', 'material');
                  }
                "
              ></label>
              <input
                type="radio"
                id="radio1"
                name="search_opt"
                :checked="radioState.radio_material"
              />
              <label for="radio1" class="label_txt">원자재</label>
            </div>
            <div>
              <label
                for="radio2"
                class="label"
                :class="{ active: radioState.radio_process }"
                @click="
                  () => {
                    $store.commit('setRadioStateToQualityPrint', 'process');
                  }
                "
              ></label>
              <input
                type="radio"
                id="radio2"
                name="search_opt"
                :checked="radioState.radio_process"
              />
              <label for="radio2" class="label_txt">공정</label>
            </div>
            <div>
              <label
                for="radio3"
                class="label"
                :class="{ active: radioState.radio_product }"
                @click="
                  () => {
                    $store.commit('setRadioStateToQualityPrint', 'product');
                  }
                "
              ></label>
              <input
                type="radio"
                id="radio3"
                name="search_opt"
                :checked="radioState.radio_product"
              />
              <label for="radio3" class="label_txt">완제품</label>
            </div>
          </div>
          <button class="btn_search2" @click="searchPrint">검색</button>
        </div>
      </div>
      <h6>
        조회수 :
        {{
          radioState.radio_material
            ? material_defect_list.length
            : radioState.radio_process
            ? seq_defect_list.length
            : radioState.radio_product
            ? filteredProductDefectList.length
            : 0
        }}건
      </h6>
      <div class="cont">
        <div v-if="radioState.radio_material">
          <div class="mes_tbl_wrap quality_print_table">
            <table class="mes_tbl">
              <colgroup>
                <col v-for="(n, index) in 15" :key="index" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>구매처</th>
                  <th>원자재명</th>
                  <th>검사일자</th>
                  <th>규격</th>
                  <th>검사항목</th>
                  <th>합격 기준</th>
                  <th>검사기구</th>
                  <th>검사시기</th>
                  <th>검사수량</th>
                  <th>합격수량</th>
                  <th>불합격수량</th>
                  <th>합격여부</th>
                  <th>첨부이미지</th>
                  <th>
                    <button class="check_btn" @click="selectAll()">
                      성적서 선택
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in material_defect_list"
                  :class="{ print_active: item.check_print }"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ findInfoFromId(companys, item.company_id).name }}</td>
                  <td>
                    {{ findInfoFromId(materials, item.material_id).name }}
                  </td>
                  <td>{{ item.create_time | formatDateNoHours }}</td>
                  <td>
                    {{ findInfoFromId(materials, item.material_id).standard }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_item
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .pass_standard
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_equipment
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_timing
                    }}
                  </td>
                  <td>
                    {{
                      ($makeNumber(item.pass_quantity) +
                        $makeNumber(item.unpass_quantity))
                        | makeComma
                    }}
                  </td>
                  <td>
                    {{ item.pass_quantity | makeComma }}
                  </td>
                  <td>
                    {{ item.unpass_quantity | makeComma }}
                  </td>
                  <td>{{ item.pass_yn ? 'Y' : 'N' }}</td>
                  <td>
                    <button
                      v-if="item.image != null"
                      class="check_btn"
                      @click="
                        () => {
                          imageTemp = item.image;
                          showImageModal = true;
                        }
                      "
                    >
                      확인
                    </button>
                  </td>
                  <td>
                    <div class="input_checkbox">
                      <label
                        :for="`checkbox${index}`"
                        class="chk_box"
                        :class="{ active: item.check_print }"
                        @click="item.check_print = !item.check_print"
                      >
                        <i class="fa fa-check"></i>
                      </label>
                      <input type="checkbox" :id="`checkbox${index}`" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="radioState.radio_process">
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <colgroup>
                <col v-for="(n, index) in 15" :key="index" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>제품명</th>
                  <th>공정명</th>
                  <th>검사일자</th>
                  <th>규격</th>
                  <th>검사항목</th>
                  <th>합격 기준</th>
                  <th>검사기구</th>
                  <th>검사시기</th>
                  <th>검사수량</th>
                  <th>합격수량</th>
                  <th>불합격수량</th>
                  <th>합격여부</th>
                  <th>첨부이미지</th>
                  <th>
                    <button class="check_btn" @click="selectAll()">
                      성적서 선택
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in seq_defect_list"
                  :key="index"
                  :class="{ print_active: item.check_print }"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ findInfoFromId(products, item.product_id).name }}</td>
                  <td>{{ findInfoFromId(process, item.process_id).name }}</td>
                  <td>{{ item.create_time | formatDate }}</td>
                  <td>
                    {{ findInfoFromId(materials, item.process_id).standard }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_item
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .pass_standard
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_equipment
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_timing
                    }}
                  </td>
                  <td>
                    {{
                      ($makeNumber(item.pass_quantity) +
                        $makeNumber(item.unpass_quantity))
                        | makeComma
                    }}
                  </td>
                  <td>
                    {{ item.pass_quantity | makeComma }}
                  </td>
                  <td>
                    {{ item.unpass_quantity | makeComma }}
                  </td>
                  <td>{{ item.pass_yn ? 'Y' : 'N' }}</td>
                  <td>
                    <button
                      v-if="item.image != null"
                      class="check_btn"
                      @click="
                        () => {
                          imageTemp = item.image;
                          showImageModal = true;
                        }
                      "
                    >
                      확인
                    </button>
                  </td>
                  <td>
                    <div class="input_checkbox">
                      <label
                        :for="`checkbox${index}`"
                        class="chk_box"
                        :class="{ active: item.check_print }"
                        @click="item.check_print = !item.check_print"
                      >
                        <i class="fa fa-check"></i>
                      </label>
                      <input type="checkbox" :id="`checkbox${index}`" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="radioState.radio_product">
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <colgroup>
                <col v-for="(n, index) in 15" :key="index" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>LOT NO.</th>
                  <th>제품명</th>
                  <th>검사일자</th>
                  <th>규격</th>
                  <th>검사항목</th>
                  <th>합격 기준</th>
                  <th>검사기구</th>
                  <th>검사시기</th>
                  <th>검사수량</th>
                  <th>합격수량</th>
                  <th>불합격수량</th>
                  <th>합격여부</th>
                  <th>첨부이미지</th>
                  <th>
                    <button class="check_btn" @click="selectAll()">
                      성적서 선택
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in filteredProductDefectList"
                  :key="index"
                  :class="{ print_active: item.check_print }"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.barcode_num }}</td>
                  <td>{{ findInfoFromId(products, item.product_id).name }}</td>
                  <td>{{ item.create_time | formatDate }}</td>
                  <td>
                    {{ findInfoFromId(products, item.product_id).standard }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_item
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .pass_standard
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_equipment
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(verifications, item.verification_id)
                        .inspection_timing
                    }}
                  </td>
                  <td>
                    {{
                      ($makeNumber(item.pass_quantity) +
                        $makeNumber(item.unpass_quantity))
                        | makeComma
                    }}
                  </td>
                  <td>
                    {{ item.pass_quantity | makeComma }}
                  </td>
                  <td>
                    {{ item.unpass_quantity | makeComma }}
                  </td>
                  <td>{{ item.pass_yn ? 'Y' : 'N' }}</td>
                  <td>
                    <button
                      v-if="item.image != null"
                      class="check_btn"
                      @click="
                        () => {
                          imageTemp = item.image;
                          showImageModal = true;
                        }
                      "
                    >
                      확인
                    </button>
                  </td>
                  <td>
                    <div class="input_checkbox">
                      <label
                        :for="`checkbox${index}`"
                        class="chk_box"
                        :class="{ active: item.check_print }"
                        @click="item.check_print = !item.check_print"
                      >
                        <i class="fa fa-check"></i>
                      </label>
                      <input type="checkbox" :id="`checkbox${index}`" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <upload-image-modal
      v-if="showImageModal"
      :uploadedImage="imageTemp"
      :index="-1"
      @onclose="showImageModal = false"
      @onselect="showImageModal = false"
    ></upload-image-modal>
    <lot-search
      v-if="showLotSearch"
      :filterWaiting="true"
      :prop_startDate="startDate"
      :prop_endDate="endDate"
      @onclose="showLotSearch = false"
      @onselect="
        $event => {
          selectLot($event);
          showLotSearch = false;
        }
      "
    ></lot-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import UploadImageModal from '@/layouts/components/UploadImageModal';
import LotSearch from '@/layouts/components/search-popup/LotSearch';

export default {
  components: {
    UploadImageModal,
    LotSearch,
  },
  data() {
    return {
      imageTemp: null,
      showImageModal: false,
      showLotSearch: false,
    };
  },
  mixins: [fetchMixin],
  computed: {
    ...mapGetters({
      startDate: 'getStartDateFromQualityPrint',
      endDate: 'getEndDateFromQualityPrint',
      date: 'getDateFromQualityPrint',
      radioState: 'getRadioStateFromQualityPrint',
      material_defect_list: 'getMaterialDefectListFromQualityPrint',
      product_defect_list: 'getProductDefectListFromQualityPrint',
      seq_defect_list: 'getSeqDefectListFromQualityPrint',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      products: 'getProduct',
      process: 'getProcess',
      verifications: 'getBaseVerificationFromQualityManagementNotSort',
    }),

    filteredProductDefectList() {
      if (this.product_defect_list.length > 0) {
        const productList = this.product_defect_list;
        return productList.sort(
          (a, b) => new Date(b.create_time) - new Date(a.create_time),
        );
      } else return [];
    },
  },
  methods: {
    selectAll() {
      if (this.material_defect_list && this.radioState.radio_material) {
        if (this.material_defect_list.find(x => !x.check_print)) {
          this.material_defect_list.forEach(element => {
            element.check_print = true;
          });
        } else {
          this.material_defect_list.forEach(element => {
            element.check_print = !element.check_print;
          });
        }
      } else if (this.product_defect_list && this.radioState.radio_product) {
        if (this.product_defect_list.find(x => !x.check_print)) {
          this.product_defect_list.forEach(element => {
            element.check_print = true;
          });
        } else {
          this.product_defect_list.forEach(element => {
            element.check_print = !element.check_print;
          });
        }
      } else if (this.seq_defect_list && this.radioState.radio_process) {
        if (this.seq_defect_list.find(x => !x.check_print)) {
          this.seq_defect_list.forEach(element => {
            element.check_print = true;
          });
        } else {
          this.seq_defect_list.forEach(element => {
            element.check_print = !element.check_print;
          });
        }
      }
      if (
        this.material_defect_list.length > 0 &&
        this.radioState.radio_material
      ) {
        return true;
      } else if (
        this.seq_defect_list.length > 0 &&
        this.radioState.radio_process
      ) {
        return true;
      } else if (
        this.product_defect_list.length > 0 &&
        this.radioState.radio_product
      ) {
        return true;
      } else return false;
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '검사일자 조회기간 오류',
            `검사일자 조회기간 최후날짜(${this.endDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToQualityPrint', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToQualityPrint', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '검사일자 조회기간 오류',
            `검사일자 조회기간 최초날짜(${this.startDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToQualityPrint', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToQualityPrint', yyyymmdd(new Date()));
      }
    },
    async selectLot(arg) {
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_SEQ_VERIFICATION_DEFECT_ALL',
        arg.id,
      );
    },
    async searchPrint() {
      if (this.radioState.radio_material == true) {
        await this.FETCH_WITH_PAYLOAD(
          'FETCH_MATERIAL_VERIFICATION_DEFECT_ALL',
          {
            start: this.startDate,
            end: this.endDate,
          },
        );
      } else if (this.radioState.radio_process == true) {
        this.showLotSearch = true;
      } else {
        await this.FETCH_WITH_PAYLOAD('FETCH_PRODUCT_VERIFICATION_DEFECT_ALL', {
          start: this.startDate,
          end: this.endDate,
        });
      }
    },
  },
  async created() {
    if (this.startDate == null) {
      var date = new Date();
      this.$store.commit('setEndDateToQualityPrint', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 14));
      this.$store.commit('setStartDateToQualityPrint', yyyymmdd(date2));
    } else {
      this.$emit('startDate', this.startDate);
      this.$emit('endDate', this.endDate);
    }

    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.verifications.length < 1) {
      await this.FETCH('FETCH_BASE_VERIFICATION', '검사기준');
    }
  },
};
</script>

<style lang="scss" scoped></style>
