<template>
  <div class="modalPopup print_modal quality_print_modal" v-if="modalClose">
    <div class="modal_header">
      <h2 class="title">검사성적서 출력</h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
      <ul class="option">
        <li>
          <h6>[선택옵션]</h6>
          <span>{{
            radioState.radio_material == true
              ? '원자재'
              : radioState.radio_process
              ? '공정'
              : '완제품'
          }}</span>
        </li>
        <li>
          <h6>조회기간</h6>
          {{ `${startDate} ~ ${endDate}` }}
        </li>
      </ul>
    </div>
    <div class="modal_body">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 13" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>
                {{
                  radioState.radio_material == true
                    ? '구매처'
                    : radioState.radio_process
                    ? '제품명'
                    : 'LOT NO.'
                }}
              </th>
              <th>
                {{
                  radioState.radio_material == true
                    ? '원자재명'
                    : radioState.radio_process
                    ? '공정명'
                    : '제품명'
                }}
              </th>
              <th>검사일자</th>
              <th>규격</th>
              <th>검사항목</th>
              <th>합격 기준</th>
              <th>검사기구</th>
              <th>검사시기</th>
              <th>검사수량</th>
              <th>합격수량</th>
              <th>불합격수량</th>
              <th>합격여부</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <div class="image_wrap" v-if="imageList.length != 0">
        <ul>
          <li v-for="image in imageList" :key="image.id">
            <p>{{ image.image_index }}.</p>
            <img :src="`data:image/png;base64,${image.image}`" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  props: ['imageList'],
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  data() {
    return {
      modalClose: true,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      material: 'getMaterial',
      startDate: 'getStartDateFromQualityPrint',
      endDate: 'getEndDateFromQualityPrint',
      radioState: 'getRadioStateFromQualityPrint',
    }),
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      const qualityHeader = document.querySelector(
        '.quality_print_modal .modal_header',
      );
      const qualityTable = document.querySelector(
        '.quality_print_modal .modal_body',
      );
      const printDiv = document.createElement('div');
      let qualityHeaderClone = qualityHeader.cloneNode(true);
      let qualityTableClone = qualityTable.cloneNode(true);
      printDiv.className = 'quality_print_wrap';
      document.body.appendChild(printDiv);
      printDiv.append(qualityHeaderClone, qualityTableClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
  },
  async created() {
    await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    await this.FETCH('FETCH_COMPANY', '구매처');
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 landscape !important;
    margin: 0 0 auto 0 !important;
  }
}
</style>
